const useQueryParams = () => {
    const url = new URL(window.location.href);
    const customerId = url.searchParams.get('customerId') || '';
    const referenceId = url.searchParams.get('referenceId') || '';
    const token = url.searchParams.get('token') || '';
    const title = url.searchParams.get('title')
    const showClipboard = url.searchParams.get('showclipboard') === 'true';
    const align = url.searchParams.get('align') || 'center'

    return {
        customerId,
        referenceId,
        token,
        title,
        showClipboard,
        align
    };
};

export default useQueryParams;