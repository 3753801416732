import { Text, Loader } from '@mantine/core';
import { useEffect } from 'react';

import { useFetch } from 'hooks/useFetch';
import {
  getTimelineEvents,
  sortTimelineEventsDesc,
} from 'services/timeline-service';
import { IDataTimeline } from 'types/timeline-types';
import Timeline from 'components/Timeline';

interface IProps {
  customerId: string;
  referenceId: string;
  token: string;
}

const TimelineView = ({ referenceId, customerId, token }: IProps) => {
  const {
    doRequest,
    error,
    isLoading,
    data: timelineEvents = { values: [] },
  } = useFetch<IDataTimeline>({
    requestService: () => getTimelineEvents({ customerId, referenceId, token }),
  });

  useEffect(() => {
    doRequest();
  }, []);

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Text color="red">{error}</Text>
  ) : !timelineEvents?.values.length ? (
    <Text color="orange">No events Exists!</Text>
  ) : (
    <Timeline events={sortTimelineEventsDesc(timelineEvents?.values)} />
  );
};

export default TimelineView;
