import { useState, useCallback } from 'react';

interface IUseFetch {
  requestService: () => Promise<Response>;
  onSuccess?: () => void;
}

export const useFetch = <Data>({ requestService, onSuccess }: IUseFetch) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<null | Data>(null);

  const doRequest = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await requestService();
      const data = await response.json();
      setData(data);
      setError(null);
      onSuccess && onSuccess();
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [onSuccess, requestService]);

  return {
    error,
    isLoading,
    data,
    doRequest,
  };
};
