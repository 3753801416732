import { Center, Container, Stack, Text, useMantineTheme
 } from '@mantine/core';
import useQueryParams from 'hooks/useQueryParams';

import TimelineView from 'views/Timeline-view';


function App() {
  const theme = useMantineTheme();
  const { customerId, referenceId, token, title, align } = useQueryParams();

  const alignStyle = align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center' 

  return (
    <Container size="lg" p="lg">
      <Stack align={ alignStyle || 'center'}>
        {
          title &&
          <Center p="md">
            <Text
              size="xl"
              style={{
                fontSize: '4rem',
                color: theme.colors.blue[5],
                textAlign: 'center',
              }}
            >
              {title}
            </Text>
          </Center>
        }
          <TimelineView
            customerId={customerId}
            referenceId={referenceId}
            token={token}
          />
      </Stack>
    </Container>
  );
}

export default App;
